<template>
    <div class="w-full h-full fixed top-0 left-0 flex justify-center items-center z-50"
        style="background-color: rgba(0, 0, 0, .25);" v-if="overlayActive">
        <div class="w-full max-w-3xl relative rounded-md py-4 px-5 flex flex-col h-full sm:h-auto overflow-y-scroll"
            style="background-color: #e9e9e9">
            <db-headline class="-mt-4 -mb-3 w-8/12">Einstellungen</db-headline>
            <db-button icon="close" icononly class="absolute top-0 right-0 p-3"
                @click="overlayActive = false; resetLoadedTenantForm();">Overlay
                schließen</db-button>
            <div v-if="overlayPage == 0">
                <div class="mt-5">
                    <db-breadcrumb aria-label="Breadcrumb">
                        <li>Einstellungen</li>
                    </db-breadcrumb>
                </div>
                <div class="flex items-center justify-between mt-4 mb-3">
                    <p class="font-medium">Tenants verwalten</p>
                    <db-button variant="primary" icon="add" icononly
                        @click="resetLoadedTenantForm(); overlayPage = 1; editTenant = false;">Hinzufügen</db-button>
                </div>
                <div class="min-h-32 w-full flex gap-2 flex-wrap">
                    <p class="m-auto italic text-sm text-gray-800" v-if="tenants.length == 0">Bisher wurden keine
                        Tenants
                        hinzugefügt</p>
                    <template v-for="i in tenants" :key="i" v-else>
                        <div
                            class="h-28 grow min-w-32 w-2/6 md:w-1/4 bg-white rounded-md shadow-md flex flex-col relative p-3 overflow-hidden">
                            <div class="ml-auto h-8 w-8 rounded-bl-xl flex items-start justify-end absolute top-0 right-0"
                                style="background-color: #282d37;">
                                <img src="/assets/icons/cloud.svg" class="h-3.5 invert" style="margin: 7px 8px;">
                            </div>
                            <div class="flex flex-col items-start gap-2">
                                <p class="font-medium">{{ i.tenant_name }}</p>
                                <p class="font-light text-xs text-gray-400 text-ellipsis w-8/12 overflow-hidden whitespace-nowrap"
                                    :title="i.tenant_id">{{ i.tenant_id }}</p>
                            </div>
                            <div class="ml-auto mt-1" v-if="deleteTenantById != i.tenant_id">
                                <db-button icon="edit" icononly
                                    @click="overlayPage = 1; loadedTenant = i; editTenant = true;">Tenant
                                    bearbeiten</db-button>
                                <db-button class="-ml-2 -mr-1" icon="delete" icononly
                                    @click="deleteTenantById = i.tenant_id">Tenant löschen</db-button>
                            </div>
                            <div class="mt-1" v-else>
                                <div class="ml-auto hidden sm:flex flex-wrap justify-end">
                                    <db-button icon="delete" style="color: #FC0016"
                                        @click="removeTenant(deleteTenantById); deleteTenantById = '';">Löschen</db-button>
                                    <db-button icon="close" variant class="-ml-2"
                                        @click="deleteTenantById = ''">Abbrechen</db-button>
                                </div>
                                <div class="ml-auto flex sm:hidden flex-wrap justify-end">
                                    <db-button icon="delete" style="color: #FC0016" icononly
                                        @click="removeTenant(deleteTenantById); deleteTenantById = '';">Löschen</db-button>
                                    <db-button icon="close" variant class="-ml-2" icononly
                                        @click="deleteTenantById = ''">Abbrechen</db-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div v-if="overlayPage == 1">
                <div class="mt-5">
                    <db-breadcrumb aria-label="Breadcrumb">
                        <li><db-link @click="overlayPage = 0;" class="cursor-pointer">Einstellungen</db-link></li>
                        <li>{{ !editTenant ? 'Neues Tenant hinzufügen' : 'Bestehendes Tenant bearbeiten' }}</li>
                    </db-breadcrumb>
                </div>
                <div class="flex flex-col gap-3 mt-4 mb-3">
                    <p class="font-medium">{{ !editTenant ? 'Neues Tenant hinzufügen' : 'Bestehendes Tenant bearbeiten'
                        }}</p>
                    <db-notifications variant="alert" v-if="!editTenant" class="-mb-4">
                        <db-notification>Nach der Registrierung des Tenants kann es bis zu <span class="font-bold">5
                                Minuten</span>
                            dauern, bis die Daten aus der Schnittstelle zur Verfügung stehen.</db-notification>
                    </db-notifications>
                    <div class="mt-2 flex gap-2 flex-wrap">
                        <db-input label="Tenant Name" variant="outline" class="min-w-64 w-2/6 grow"
                            v-model="loadedTenant.tenant_name"></db-input>
                        <db-input label="Tenant ID" variant="outline" class="min-w-64 w-2/6 grow"
                            v-model="loadedTenant.tenant_id" :disabled="editTenant"></db-input>
                        <db-input label="Client ID" variant="outline" class="min-w-64 w-2/6 grow"
                            v-model="loadedTenant.client_id"></db-input>
                        <db-input label="Client Secret" variant="outline" type="password" class="min-w-64 w-2/6 grow"
                            v-model="loadedTenant.client_secret"></db-input>
                        <db-input label="Mail (optional)" variant="outline" class="min-w-64 w-2/6 grow"
                            v-model="loadedTenant.send_as_mail"></db-input>
                    </div>
                    <p class="text-red-500 text-sm" v-if="errors.dataIncomplete">Angaben unvollständig!
                    </p>
                    <div class="flex gap-1 ml-auto">
                        <db-button variant="primary" @click="saveNewTenant">Speichern</db-button>
                        <db-button variant="secondary-outline"
                            @click="resetLoadedTenantForm(); overlayPage = 0;">Abbrechen</db-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            overlayActive: false,
            overlayPage: 0,
            tenants: [],
            loadedTenant: {
                tenant_name: "",
                tenant_id: "",
                send_as_mail: "",
                client_secret: "",
                client_id: ""
            },
            editTenant: false,
            deleteTenantById: "",
            errors: {
                dataIncomplete: false
            }
        }
    },
    watch: {
        overlayActive(_new) {
            if (_new) {
                this.fetchAllTenants();
            }
        }
    },
    methods: {
        toggle(override = undefined) {
            this.overlayPage = 0;
            this.overlayActive = override || !this.overlayActive;
        },
        async fetchAllTenants() {
            await this.$axios.get("/tenants/all", { headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error, payload } = res.data;

                if (!error) {
                    this.tenants = payload;
                }
            })
        },
        async saveNewTenant() {
            if (this.loadedTenant.tenant_name == "" || this.loadedTenant.tenant_id == "" || this.loadedTenant.client_secret == "" || this.loadedTenant.client_id == "") {
                this.errors.dataIncomplete = true;
                return;
            }

            this.errors.dataIncomplete = false;

            this.$axios.post("/tenants/new", { ...this.loadedTenant }, { headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error } = res;

                if (!error) {
                    this.resetLoadedTenantForm();
                    this.overlayPage = 0;
                    this.editTenant = false;
                    this.fetchAllTenants();
                    this.$appStore.fetchAvailableTenants();
                }
            })
        },
        async removeTenant(id) {
            this.$axios.delete("/tenants/del", { params: { tenant_id: id }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error } = res.data;

                if (!error) {
                    this.fetchAllTenants();
                    this.$appStore.fetchAvailableTenants();
                }
            });
        },
        resetLoadedTenantForm() {
            this.loadedTenant = {
                tenant_name: "",
                tenant_id: "",
                send_as_mail: "",
                client_id: "",
                client_secret: ""
            };
            this.errors.dataIncomplete = false;
            this.deleteTenantById = "";
            this.editTenant = false;
        }
    },
    created() {
        this.fetchAllTenants();
    }
}
</script>