import { defineStore } from "pinia";
import { useAppStore } from "./appStore";

export const useUserStore = defineStore("userStore", {
    state: () => ({
        authenticated: false,
        access_token: null
    }),
    actions: {
        logoutUser() {
            this.authenticated = false;
            this.access_token = null;
            useAppStore().clear();
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: "user",
                storage: localStorage
            }
        ]
    }
});