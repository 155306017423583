<template>
    <div class="fixed top-0 left-0 w-full h-full" style="z-index: 200;" @click="menuOverlay = false;"
        v-if="menuOverlay">
        <div :style="`max-width: ${offsetWidth};`" class="mx-auto relative">
            <div class="overflow-menu">
                <div class="shadow-lg overflow-hidden rounded-md">
                    <template v-for="i in data.btns" :key="JSON.stringify(i)">
                        <button @click="i.callback">{{ i.label }}</button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "overflowMenu",
    data() {
        return {
            menuOverlay: false,
            offsetWidth: 0
        }
    },
    props: {
        data: Object,
    },
    methods: {
        toggle(override = undefined) {
            this.menuOverlay = override || !this.menuOverlay;
            this.offsetWidth = document.querySelector(".rea-main").offsetWidth + "px";
        }
    },
}
</script>

<style lang="scss">
.overflow-menu {
    --overflow-menu-background: #282d37;
    --button-hover-color: rgba(255, 255, 255, .15);
    --text-color: white;
    position: absolute;
    top: 70px;
    right: 10px;
    z-index: 500;
    max-width: 180px;
    width: 100%;
    isolation: isolate;

    div {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        background-color: var(--overflow-menu-background);
    }

    Button {
        height: 40px;
        text-align: left;
        padding: 5px 10px;
        color: var(--text-color);

        &:hover {
            background-color: var(--button-hover-color);
        }
    }

    /* &::before {
        position: absolute;
        top: -20px;
        right: 20px;
        content: "";
        width: 20px;
        aspect-ratio: 1;
        clip-path: polygon(50% 50%, 0% 100%, 100% 100%);
        background-color: var(--overflow-menu-background);
    } */
}
</style>