import axios from "axios";
import router from "@/router/index";
import { useUserStore } from "@/store/userStore";

const api = {
    install(app) {
        const api = axios.create({
            // baseURL: "https://awsdf-monitoring.comp.db.de/api",
            baseURL: "https://limos.schneider-jonas.dev/api",
            // withCredentials: true,
        });

        api.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status !== 404) {
                    console.log('Unauthorized: Redirect to login page');
                    useUserStore().logoutUser();
                    router.push("/");
                }
                return Promise.reject(error);
            }
        );

        app.config.globalProperties.$axios = api;
    }
}

export default api;