import { defineStore } from "pinia";
import { app } from "../main.js";

export const useAppStore = defineStore("appStore", {
    state: () => ({
        selectedTenant: null,
        availableTenants: [],
        blockTenantChange: false,
        deleteProfiles: []
    }),
    actions: {
        async fetchAvailableTenants() {
            const { $axios: axios, $userStore: userStore } = app.config.globalProperties;

            try {
                const { data } = await axios.get("/utils/tenants", { headers: { 'Authorization': `Bearer ${userStore.access_token}` } });

                if (!data.error) {
                    this.availableTenants = data.payload.map(m => { return {label: m} });
                    this.selectedTenant = this.availableTenants[0];
                } else {
                    console.error("Error while fetching '/utils/tenants' from backend: ", data);
                }
            } catch (e) {
                console.error("Error in 'fetchAvailableTenants'", e)
            }
        },
        changeSelectedTenant(target) {
            this.selectedTenant = target;
        },
        async getSelectedTenant() {
            if (!this.selectedTenant) {
                try {
                    await this.fetchAvailableTenants();
                } catch (e) {
                    console.error("Error in 'getSelectedTenant': ", e);
                }
            }
            return this.selectedTenant?.label;
        },
        async getAvailableTenants() {
            if (!this.selectedTenant) {
                try {
                    await this.fetchAvailableTenants();
                } catch (e) {
                    console.error("Error in 'getAvailableTenants': ", e);
                }
            }
            return this.availableTenants;
        },
        clear() {
            this.selectedTenant = null;
            this.availableTenants = [];
            this.blockTenantChange = false;
        }
    }
});
