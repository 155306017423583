<template>
    <div style="min-width: 200px; width: 30%; height: 170px; background-color: #131821;"
        class="hover:license cursor-pointer flex flex-col flex-grow rounded-lg relative">
        <span class="absolute flex h-3 w-3 top-2 right-2" v-if="i.minAlert">
            <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
        </span>
        <div class="p-2 flex flex-col flex-grow relative overflow-hidden rounded-lg">
            <div class="z-10 flex flex-col h-full">
                <div class="cursor-default w-10/12 z-10 max-h-14 hover:max-h-96 hover:whitespace-normal shadow-md whitespace-nowrap duration-200 px-3 py-2 rounded-md"
                    style="background-color: #3C414B;" @click="(e) => { e.preventDefault(); e.stopPropagation() }">
                    <p class="text-gray-400 text-xs m-0">Lizenz</p>
                    <p class="text-white overflow-ellipsis overflow-hidden">{{ i.name }}</p>
                </div>
                <div class="flex mt-2 z-10" v-if="lastValueChange">
                    <div class="p-1 rounded-lg flex gap-1 items-center" style="
							background: rgba(255, 255, 255, 0);
							box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
							backdrop-filter: blur(8.4px);
							-webkit-backdrop-filter: blur(8.4px);
							border: 1px solid rgba(255, 255, 255, 0.24);">
                        <img :src="`/assets/icons/ic-db_navigation_arrow-${lastValueChange > 0 ? 'up' : 'down'}_32.svg`"
                            class="w-6" style="-webkit-user-drag: none;">
                        <p class="text-white text-sm pr-2">{{ lastValueChange }}</p>
                    </div>
                </div>
                <div class="flex gap-1 items-top mt-auto ml-auto px-4 pb-3 absolute bottom-0 right-0">
                    <p :class="`font-bold text-4xl ${i.minAlert ? 'text-red-500' : 'text-white'}`">{{ i.left }}</p>
                    <p class="text-gray-300">von</p>
                    <p class="text-gray-300">{{ i.total > 999 ? Math.min(i.total, 999) + "+" : i.total }}</p>
                </div>
            </div>
            <div class="absolute h-1/2 -bottom-1" style="width: 104%; left: -2%;">
                <Line id="chart-1" :options="_chartOptions" :data="getChartForLicense(i.skuId)" />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

export default {
    name: 'licenseObj',
    components: {
        Line
    },
    props: {
        i: Object,
        getChartForLicense: Function,
        getLastValueChange: Function
    },
    data() {
        return {
            lastValueChange: null,
            _chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        display: false
                    },
                    y: {
                        display: false,
                        beginAtZero: true,
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            },
        }
    },
    created() {
        this.lastValueChange = this.getLastValueChange(this.i?.skuId);
    }
}
</script>

<style></style>