<template>
	<div class="flex md:flex-row flex-col md:items-center md:justify-between md:mb-0 mb-3">
		<db-headline pulse>Lizenzen <span class="font-normal text-base ml-2">({{ $appStore.selectedTenant?.label
				}})</span></db-headline>
		<div class="flex justify-end w-full md:w-auto">
			<db-input label="Suche" variant="outline" @change="search = $event.target.value"
				@keydown="search = $event.target.value" class="mr-1"></db-input>
			<db-select v-model="sortKey" label="Sortieren" variant="outline" class="mr-1" ariainvalid="false"
				style="width: 200px;">
				<option selected>Übrig aufsteigend</option>
				<option>Übrig absteigend</option>
				<option>alphabetisch</option>
			</db-select>
			<db-select @change="selectedProfile = profiles.findIndex(f => f.name == $event.target.value)"
				:key="JSON.stringify(profiles)" label="Profil" variant="outline" class="mr-2" ariainvalid="false"
				style="width: 200px;">
				<option selected>keins</option>
				<template v-for="i in profiles" :key="i.id">
					<option>{{ i?.name }}</option>
				</template>
			</db-select>
			<db-button variant="primary" icon="filter" icononly
				@click="$refs.profileOverlay.toggleOverlay();">Button</db-button>
		</div>
	</div>

	<db-notifications variant="alert" v-if="selectedProfile != -1 && filteredData.some(s => s.minAlert)"
		class="-mb-2 rounded-lg">
		<db-notification>
			Für ausgewählte Lizenzen wurde der minimale Schwellenwert unterschritten!
		</db-notification>
	</db-notifications>

	<div class="flex gap-2 flex-wrap p-2 items-start justify-start">
		<licenseObj v-for="i in filteredData" :key="i.skuId" :i="i" :getLastValueChange="getLastValueChange"
			:getChartForLicense="getChartForLicense" @click="$refs.detailOverlay.i = i"></licenseObj>
	</div>

	<p class="text-xs m-auto max-w-sm text-center" v-if="originalData.length == 0">Bisher wurden keine Daten für das
		Tenant
		hinterlegt.<br> Solltest du es gerade hinzugefügt haben, warte bitte ein Moment.</p>

	<p class="text-xs m-auto max-w-sm text-center" v-else-if="filteredData.length == 0">Es wurden keine Übereinstimmungen gefunden.</p>

	<licenseDetailOverlay ref="detailOverlay" :getChartForLicense="getChartForLicense" :originalData="originalData"
		v-if="sortedData?.length > 0" :lastRefresh="this.originalData[this.originalData?.length - 1]?.timestamp">
	</licenseDetailOverlay>

	<profileOverlay :all-licenses="sortedData" ref="profileOverlay" @profilesChanged="getAllProfiles"></profileOverlay>
</template>

<script>
/* eslint-disable */
import licenseObj from "@/components/licenses_page/licenseObj.vue";
import licenseDetailOverlay from "@/components/licenses_page/licenseDetailOverlay.vue";
import profileOverlay from "@/components/licenses_page/profileOverlay.vue";

export default {
	name: 'licenses',
	components: {
		licenseObj,
		licenseDetailOverlay,
		profileOverlay
	},
	data() {
		return {
			originalData: [],
			profiles: [],
			selectedProfile: -1,
			sortKey: "",
			search: ""
		}
	},
	mounted() {
		this.reload();
	},
	computed: {
		sortedData() {
			if (!this.originalData[this.originalData.length - 1]) return [];
			let copy = JSON.parse(JSON.stringify(this.originalData[this.originalData.length - 1]));
			switch (this.sortKey) {
				default:
				case "Übrig aufsteigend":
					return copy?.licenses.sort((a, b) => a.left > b.left ? 1 : -1);
				case "Übrig absteigend":
					return copy?.licenses.sort((a, b) => a.left < b.left ? 1 : -1);
				case "alphabetisch":
					return copy?.licenses.sort((a, b) => a.name > b.name ? 1 : -1);
			}
		},
		filteredData() {
			let filtered = [];

			if (this.selectedProfile == -1 || !this.profiles[this.selectedProfile]?.selectedItems) {
				filtered = this.sortedData;
			} else {
				this.sortedData.forEach(e => {
					//check if element is in selectedItems
					let target = this.profiles[this.selectedProfile].selectedItems.find(i => i.skuId == e.skuId);

					if (target) {
						filtered.push({ ...e, minAlert: e.left <= target.min });
					}
				});
			}

			return filtered.filter(m => m.name.includes(this.search) && m.name != "Nicht gefunden");
		}
	},
	methods: {
		getLastValueChange(skuId) {
			let copy = JSON.parse(JSON.stringify(this.originalData));
			let history = copy.reverse().slice(0, 10).map(m => m.licenses.find(f => f.skuId === skuId)?.left || 0)
			let change = null;

			for (let i = 0; i < history.length - 1; i++) {
				if (history[i] != history[i + 1]) {
					change = history[i] - history[i + 1];
					break;
				}
			}

			return change > 0 ? "+" + change : change;
		},
		getChartForLicense(skuId, amount = 10) {
			let copy = JSON.parse(JSON.stringify(this.originalData));
			let history = copy.slice(-amount).map(m => { return { timestamp: m.timestamp, left: m.licenses.find(f => f.skuId === skuId)?.left || 0 } });
			let _chartData = {
				labels: history.map(m => m.timestamp * 1000),
				datasets: [{
					data: history.map(m => m.left),
					backgroundColor: `rgba(20, 85, 192, 0.3)`,
					borderColor: `rgba(20, 85, 192, 1)`,
					borderWidth: 2,
					fill: true,
					pointRadius: 0,
				}]
			};
			return _chartData;
		},
		reload() {
			this.fetchLicenseData();
			this.getAllProfiles();
			this.selectedProfil = null;
		},
		async fetchLicenseData() {
			await this.$axios.get("/licenses", { params: { tenant: await this.$appStore.getSelectedTenant() }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
				let { error, payload } = res.data;

				if (!error) {
					this.originalData = payload;
				} else {
					setTimeout(() => {
						this.$appStore.fetchAvailableTenants();
						this.fetchLicenseData();
					}, 2000)
				}
			})
		},
		async getAllProfiles() {
			await this.$axios.get("/profile/all", { params: { tenant: await this.$appStore.getSelectedTenant() }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
				let { error, payload } = res.data;

				if (!error) {
					this.profiles = payload;
				} else {
					setTimeout(() => {
						this.$appStore.fetchAvailableTenants();
						this.getAllProfiles();
					}, 2000)
				}
			})
		},
	},
	watch: {
		"$appStore.selectedTenant": {
			handler() {
				this.reload();
				this.selectedProfile = -1;
			},
			deep: true
		}
	}
}
</script>

<style lang="scss">
.hover\:license {
	transition: all 200ms;
	user-select: none;

	&:hover {
		transform: scale(1.02);
	}

	&:active {
		transform: scale(0.99);
	}
}
</style>