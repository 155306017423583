<template>
    <div class="w-full h-full fixed top-0 left-0 flex justify-center items-center z-50"
        style="background-color: rgba(0, 0, 0, .25);" v-if="i">
        <div class="w-full max-w-5xl relative rounded-md py-4 px-5 flex flex-col h-full sm:h-auto overflow-y-scroll"
        style="background-color: #e9e9e9">
            <db-headline class="-mt-4 -mb-3 w-8/12">{{ i.name }}</db-headline>
            <p class="text-sm">{{ formatDistanceToNow(lastRefresh * 1000, { addSuffix: true, locale: de }) }} aktualisiert
            </p>
            <db-button icon="close" icononly class="absolute top-0 right-0 p-3" @click="i = null">Overlay schließen</db-button>
            <div class="mt-6">
                <p class="font-medium mb-4">Aktuell</p>
                <div class="flex gap-1">
                    <div class="min-w-1/4 flex-auto bg-white shadow-md rounded-md flex flex-col items-center p-3 py-5 gap-2">
                        <p class="my-auto font-bold text-xl">{{ i.left }}</p>
                        <p class="text-sm text-gray-500">Übrig</p>
                    </div>
                    <div class="min-w-1/4 flex-auto bg-white shadow-md rounded-md flex flex-col items-center p-3 py-5 gap-2">
                        <p class="my-auto font-bold text-xl">{{ i.consumed }}</p>
                        <p class="text-sm text-gray-500">Verbraucht</p>
                    </div>
                    <div class="min-w-1/4 flex-auto bg-white shadow-md rounded-md flex flex-col items-center p-3 py-5 gap-2">
                        <p class="my-auto font-bold text-xl">{{ i.total }}</p>
                        <p class="text-sm text-gray-500">Total</p>
                    </div>
                </div>
            </div>
            <div class="flex flex-col sm:flex-row mt-6 gap-4 md:gap-2">
                <div class="w-full sm:w-8/12 flex flex-col gap-3">
                    <p class="font-medium">Verlauf</p>
                    <div style="height: 400px;">
                        <Line :data="_chartData" :options="_chartOptions"></Line>
                    </div>
                </div>
                <div class="flex-auto flex flex-col gap-3 h-full overflow-y-scroll">
                    <p class="font-medium">Änderungen</p>
                    <div class="flex flex-col gap-1 rounded-lg overflow-y-scroll py-1"
                        style="height: 350px;">
                        <p v-if="lastValueChanges.length == 0" class="text-sm text-gray-800 m-auto italic">Keine Änderungen erfasst</p>
                        <template v-for="i in lastValueChanges" :key="i">
                            <div class="shadow-md py-2 px-3 flex flex-col rounded-md"
                                style="background-color: #ffffff;">
                                <div class="flex gap-2">
                                    <img :src="`/assets/icons/ic-db_navigation_arrow-${i.change > 0 ? 'up' : 'down'}_32.svg`"
                                        class="w-6" style="-webkit-user-drag: none;">
                                    <div class="flex-auto flex h-7 items-center">
                                        <p class="font-bold">{{ i.change }}</p>
                                        <p class="text-xs text-gray-500 ml-2">Lizenzen</p>
                                        <p class="text-sm ml-auto">{{ i.before }} ➜ {{ i.after }}</p>
                                    </div>
                                </div>
                                <p class="text-gray-500 text-sm text-right">{{ i.date }}</p>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TimeSeriesScale,
    TimeScale
} from 'chart.js'
import 'chartjs-adapter-date-fns';
import { de } from 'date-fns/locale';
import { Line } from 'vue-chartjs'
import { formatDistanceToNow, format } from "date-fns";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    TimeSeriesScale,
    TimeScale,
)

export default {
    name: "licenseDetailOverlay",
    components: {
        Line
    },
    props: {
        getChartForLicense: Function,
        lastRefresh: Number,
        originalData: Array
    },
    watch: {
        i() {
            if(this.i) {
                this.lastValueChanges = this.getAllValueChanges(this.i.skuId);
                this._chartData = this.getChartForLicense(this.i.skuId, 15);
                let max = Math.max(...this._chartData.datasets[0].data);
                this._chartOptions.scales.y.suggestedMax = max + 3;
            }
        }
    },
    data() {
        return {
            lastValueChanges: [],
            i: null,
            de,
            _chartData: [],
            _chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: 'timeseries',
                        time: {
                            unit: "day",
                            round: "day",
                            displayFormats: {
                                // day: "dd. MMM (HH:mm)"
                                day: "dd. MMM"
                            }
                        },
                        adapters: {
                            date: {
                                locale: de
                            }
                        },
                        // ticks: {
                        //     callback: function (value, index, values) {
                        //         let date = format(value, "dd. MMM.");
                        //         let time = format(value, "HH:MM");
                        //         if (index > 1 && date == format(values[index - 1].value, "dd. MMM.")) {
                        //             return time;
                        //         } else {
                        //             return `${date} (${time})`;
                        //         }
                        //     }
                        // }
                    },
                    y: {
                        display: true,
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1,
                            precision: 0
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            },
            data: null
        }
    },
    methods: {
        formatDistanceToNow,
        getAllValueChanges(skuId) {
            let copy = JSON.parse(JSON.stringify(this.originalData));
            let history = copy.reverse().map(m => ({timestamp: m.timestamp, left: m.licenses.find(f => f.skuId === skuId)?.left || 0}))
            let changes = [];

            for (let i = 0; i < history.length - 1; i++) {
                if (history[i].left != history[i + 1].left) {
                    let change = history[i].left - history[i + 1].left;
                    change = change > 0 ? "+" + change : change;
                    changes.push({date: format(history[i].timestamp * 1000, "dd. MMMM", {locale: this.de}), change, before: history[i+1].left, after: history[i].left});
                }
            }

            return changes.reverse();
        }
    },
}
</script>

<style lang="scss" scoped></style>