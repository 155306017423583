import { useUserStore } from "../store/userStore.js";
import { useAppStore } from "@/store/appStore.js"; 

const piniaStore = {
    install(app) {
        app.config.globalProperties.$userStore = useUserStore();
        app.config.globalProperties.$appStore = useAppStore();
    }
}

export default piniaStore;