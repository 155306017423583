<template>
	<db-page>
		<db-header>
			<db-brand src="/assets/db_logo.svg">Limos</db-brand>
			<div class="my-auto ml-auto flex gap-2">
				<db-select ref="tenantSelection" label="Tenant" variant="outline" style="width: 150px;"
					ariainvalid="false" @change="$appStore.changeSelectedTenant({ label: $event.target.value })"
					:key="JSON.stringify($appStore?.availableTenants)" v-if="$appStore.availableTenants.length > 0">
					<template v-for="i, n in availableTenants" :key="i">
						<option :selected="n == 0">{{ i?.label }}
						</option>
					</template>
				</db-select>
				<button v-if="$userStore.authenticated" title="Menü öffnen"
					class="menu-btn h-11 p-3 rounded-md hover:bg-gray-300" style="padding-top: 9px;"
					@click="this.$refs.overflowMenuBtn1.toggle()"><db-icon icon="more-vertical"
						style="--icon-margin-after: 0; --icon-font-size-before: 1.4rem;"></db-icon></button>
			</div>
		</db-header>
		<div class="flex-auto flex flex-col" style="margin: 0 16px;">
			<router-view></router-view>
		</div>
		<db-footer copyright>
			<div class="ml-auto flex gap-2">
				<span class="font-bold hidden sm:flex">Ansprechpartner:</span>
				<span class="font-bold sm:hidden flex">@</span>
				<a href="mailto:jonas.jo.schneider@deutschebahn.com">Jonas
					Schneider</a>
			</div>
		</db-footer>
	</db-page>
	<overflowMenu ref="overflowMenuBtn1" :data="{ btns: overflowMenuBtns }"></overflowMenu>
	<settingsOverlay ref="settingsOverlay"></settingsOverlay>
</template>

<script>
/* eslint-disable */
import overflowMenu from "@/components/app/overflowMenu.vue";
import settingsOverlay from "./components/app/settingsOverlay.vue";

export default {
	name: 'licenses',
	data() {
		return {
			availableTenants: [],
			overflowMenuBtns: [
				{
					label: "Einstellungen",
					callback: () => {
						this.$refs.settingsOverlay.toggle();
					}
				},
				{
					label: "Ausloggen",
					callback: () => {
						this.$userStore.logoutUser();

						let query = new URLSearchParams(window.location.search);

						if(query.get("saml") == "passed") {

							//if saml passed, use logout endpoint
							window.location.href = "https://awsdf-monitoring.comp.db.de/saml/logout"
						} else {
							this.$router.push('/');
						}
					}
				},
			]
		}
	},
	components: {
		overflowMenu,
		settingsOverlay
	},
	methods: {
		async syncAvailableTenants() {
			const _availableTenants = await this.$appStore?.getAvailableTenants();
			if (JSON.stringify(_availableTenants) != JSON.stringify(this.availableTenants))
				this.availableTenants = _availableTenants;
		},
	},
	watch: {
		"$appStore.availableTenants": {
			handler() {
				if (this.$userStore.authenticated)
					this.syncAvailableTenants();
			},
			deep: true
		}
	},
	mounted() {
		this.syncAvailableTenants();
	},
}
</script>

<style lang="scss">
.rea-header {
	border-bottom: none !important;
}

.rea-page main {
	overflow-y: auto !important;
}

.rea-page main>* {
	height: auto !important;
	flex-shrink: 0;
}

.rea-page {
	background-color: #e9e9e9;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}

@media (min-width: 1023px) {
	header {
		width: 99%;
		border-bottom-right-radius: 8px;
		border-bottom-left-radius: 8px;
		margin: 0 auto 20px;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, .2) !important;
	}

	footer {
		width: 99%;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		margin: 10px auto 0;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, .2) !important;
	}
}

::-webkit-scrollbar {
	display: none;
}
</style>
