/* eslint-disable */

import { createWebHashHistory, createRouter } from "vue-router";
import { nextTick } from "vue";
import { useUserStore } from "@/store/userStore";
import auth from "@/pages/auth.vue";
import licenses from "@/pages/licenses.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: auth,
    meta: {
      title: "Login"
    }
  },
  {
    path: "/licenses",
    name: "Lizenzen",
    component: licenses,
    meta: {
      title: "Lizenzen"
    }
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

const DEFAULT_TITLE = 'Limos';
router.beforeEach((to, from, next) => {
  nextTick(() => {
    document.title = `${DEFAULT_TITLE} | ${to.meta.title}`;
  });
  if(!useUserStore().authenticated && to.path != "/") next({path: "/"})
  else if(useUserStore().authenticated && to.path == "/") next({path: "/licenses"})
  else next();
});

export default router;