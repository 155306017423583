/* eslint-disable */

import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'

import { createPinia } from "pinia";
import piniaPersist from 'pinia-plugin-persist';

import {
    DbAccordion, DbBrand, DbBreadcrumb, DbButton, DbCard, DbCards,
    DbCheckbox, DbChip, DbDropdown, DbFooter, DbHeader, DbHeadline,
    DbIcon, DbImage, DbInput, DbLanguageSwitcher, DbLink, DbLinklist,
    DbLogo, DbMainnavigation, DbMeta, DbMetanavigation, DbNotification,
    DbNotifications, DbOverflowMenu, DbPage, DbPagination, DbProgress,
    DbRadio, DbSelect, DbSidenavi, DbTab, DbTabBar, DbTable, DbTag,
    DbTextarea, DbToggle, GithubVersionSwitcher
} from '@db-ui/v-elements/dist/components';

import './index.css'
import "@db-ui/core/dist/css/db-ui-core.vars.css";

import api from "@/bin/axios";
import store from "@/bin/store";

const pinia = createPinia();
pinia.use(piniaPersist);

const app = createApp(App);

[
    { component: DbAccordion, name: 'db-accordion' },
    { component: DbBrand, name: 'db-brand' },
    { component: DbBreadcrumb, name: 'db-breadcrumb' },
    { component: DbButton, name: 'db-button' },
    { component: DbCard, name: 'db-card' },
    { component: DbCards, name: 'db-cards' },
    { component: DbCheckbox, name: 'db-checkbox' },
    { component: DbChip, name: 'db-chip' },
    { component: DbDropdown, name: 'db-dropdown' },
    { component: DbFooter, name: 'db-footer' },
    { component: DbHeader, name: 'db-header' },
    { component: DbHeadline, name: 'db-headline' },
    { component: DbIcon, name: 'db-icon' },
    { component: DbImage, name: 'db-image' },
    { component: DbInput, name: 'db-input' },
    { component: DbLanguageSwitcher, name: 'db-language-switcher' },
    { component: DbLink, name: 'db-link' },
    { component: DbLinklist, name: 'db-linklist' },
    { component: DbLogo, name: 'db-logo' },
    { component: DbMainnavigation, name: 'db-mainnavigation' },
    { component: DbMeta, name: 'db-meta' },
    { component: DbMetanavigation, name: 'db-metanavigation' },
    { component: DbNotification, name: 'db-notification' },
    { component: DbNotifications, name: 'db-notifications' },
    { component: DbOverflowMenu, name: 'db-overflow-menu' },
    { component: DbPage, name: 'db-page' },
    { component: DbPagination, name: 'db-pagination' },
    { component: DbProgress, name: 'db-progress' },
    { component: DbRadio, name: 'db-radio' },
    { component: DbSelect, name: 'db-select' },
    { component: DbSidenavi, name: 'db-sidenavi' },
    { component: DbTab, name: 'db-tab' },
    { component: DbTabBar, name: 'db-tab-bar' },
    { component: DbTable, name: 'db-table' },
    { component: DbTag, name: 'db-tag' },
    { component: DbTextarea, name: 'db-textarea' },
    { component: DbToggle, name: 'db-toggle' },
    { component: GithubVersionSwitcher, name: 'github-version-switcher' }
].forEach(({ component, name }) => {
    app.component(name, component);
});

app.use(pinia);
app.use(router);
app.use(api);
app.use(store);

// app.use(PrimeVue);

app.mount('#app');

export {
    app
}