<template>
    <div class="h-full w-full flex flex-column justify-center items-center">
        <div class="flex flex-col gap-8 p-4 w-full sm:w-1/2 shadow-md bg-white">
            <div class="-mt-5">
                <db-headline pulse variant="1">Limos</db-headline>
                <p class="text-lg"><strong>Li</strong>censing <strong>mo</strong>nitoring
                    <strong>s</strong>ystem
                </p>
            </div>
            <div class="flex flex-col gap-4">
                <!-- <db-input label="Benutzer" type="email" variant="outline"></db-input> -->
                <db-input label="Token" type="password" variant="outline" v-model="user.token"></db-input>
            </div>
            <div class="flex gap-2">
                <db-button variant="primary" @click="performLogin">Einloggen</db-button>
                <db-button variant="brand-primary" @click="redirectToSSO" disabled="true">DB Login (TX)</db-button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            user: { token: "" },
        }
    },
    methods: {
        performLogin(_token = "") {
            this.$axios.post("/auth", { token: this.user.token || _token }).then(res => {
                let { error, payload: { access_token } } = res.data;
                if (!error) {
                    this.$userStore.authenticated = true;
                    this.$userStore.access_token = access_token;
                    this.$router.push("/licenses");
                }
            })
        },
        onEnterKeyPress(event) {
            //get input ´cause v-model not working without focus change
            if (event.key === "Enter") {
                let token = document.querySelector("input[type=password]").value;
                this.performLogin(token);
            }
        },
        redirectToSSO() {
            window.location.href = "https://awsdf-monitoring.comp.db.de/saml/sso"
        }
    },
    created() {
        document.addEventListener("keypress", this.onEnterKeyPress);

        let query = new URLSearchParams(window.location.search);
        if (query.get("saml") == "passed") {
            setTimeout(() => this.performLogin(), 500);
        }
    },
    unmounted() {
        document.removeEventListener("keypress", this.onEnterKeyPress);
    }
}
</script>