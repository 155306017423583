<template>
    <div class="w-full h-full fixed top-0 left-0 flex justify-center items-center z-50"
        style="background-color: rgba(0, 0, 0, .25);" v-if="overlayActive">
        <div class="w-full max-w-3xl relative rounded-md py-4 px-5 flex flex-col h-full sm:h-auto overflow-y-scroll"
            style="background-color: #e9e9e9">
            <db-headline class="-mt-4 -mb-3 w-8/12">{{ overlayPage == 0 ? 'Übersicht' : editProfile ? 'Bestehendes Profil bearbeiten' : 'Neues Profil erstellen'
            }}</db-headline>
            <db-button icon="close" icononly class="absolute top-0 right-0 p-3" @click="overlayActive = false; overlayPage = 0; resetLoadedProfile();">Overlay
                schließen</db-button>
            <div v-if="overlayPage == 0">
                <div class="mt-5">
                    <db-breadcrumb aria-label="Breadcrumb">
                        <li>Profile</li>
                    </db-breadcrumb>
                </div>
                <div class="flex mt-5">
                    <div class="flex flex-col gap-3 h-full overflow-y-scroll w-full">
                        <div class="flex items-center justify-between">
                            <p class="font-medium">Alle</p>
                            <db-button icon="undo" variant="primary" class="scale-90 origin-right" v-if="$appStore.deleteProfiles.length > 0" @click="undoProfileDeletion()">Rückgängig machen ({{ $appStore.deleteProfiles.length }})</db-button>
                        </div>
                        <div class="flex flex-col gap-1 rounded-lg overflow-y-scroll py-1" style="height: 450px;">
                            <div class="m-auto flex flex-col gap-3" v-if="profiles.length == 0">
                                <p class="text-sm text-gray-800 italic">Bisher wurden keine Profile erstellt.</p>
                                <db-button icon="add" class="mx-auto"
                                    @click="overlayPage = 1; resetLoadedProfile();">Neu</db-button>
                            </div>
                            <template v-for="i in profiles" :key="i">
                                <div class="shadow-md py-2 px-3 flex flex-col rounded-md gap-2"
                                    style="background-color: #ffffff;">
                                    <div class="flex items-start gap-2">
                                        <div class="flex flex-col gap-2 my-1">
                                            <p>{{ i.name }}</p>
                                            <div class="flex gap-1 h-6">
                                                <db-tag
                                                    :variant="i.webhooks.length > 0 ? 'success' : 'light'">Webhook</db-tag>
                                                <db-tag :variant="i.mails.length > 0 ? 'success' : 'light'">Mail</db-tag>
                                            </div>
                                        </div>
                                        <div class="flex-auto">
                                            <div class="flex flex-wrap justify-end" v-if="i.id != deleteProfileById">
                                                <db-button icon="edit" icononly
                                                @click="{ loadedProfile = i; overlayPage = 1; editProfile = true; }">Profil
                                                bearbeiten</db-button>
                                                <db-button icon="delete" icononly class="-ml-2"
                                                @click="deleteProfileById = i.id;">Profil löschen</db-button>
                                            </div>
                                            <div v-else>
                                                <div class="ml-auto hidden sm:flex flex-wrap justify-end">
                                                    <db-button icon="delete" style="color: #FC0016" @click="removeProfile(deleteProfileById); deleteProfileById = '';">Löschen</db-button>
                                                    <db-button icon="close" variant class="-ml-2"
                                                    @click="deleteProfileById = ''">Abbrechen</db-button>
                                                </div>
                                                <div class="ml-auto flex sm:hidden flex-wrap justify-end">
                                                    <db-button icon="delete" style="color: #FC0016" icononly @click="removeProfile(deleteProfileById); deleteProfileById = '';">Löschen</db-button>
                                                    <db-button icon="close" variant class="-ml-2" icononly
                                                    @click="deleteProfileById = ''">Abbrechen</db-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="h-0.5 w-full bg-gray-300 rounded-md"></div>
                                    <p class="text-sm text-gray-500 truncate">{{ i.selectedItems.map(m => m.name).join(", ").slice(0,
                                        150) }}</p>
                                </div>
                            </template>
                            <db-button icon="add" class="mx-auto mt-2" v-if="profiles.length > 0"
                                @click="overlayPage = 1; resetLoadedProfile();">Neu</db-button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="overlayPage == 1">
                <div class="flex flex-col gap-2 mt-5">
                    <db-breadcrumb aria-label="Breadcrumb">
                        <li><db-link @click="overlayPage = 0; resetLoadedProfile();"
                                class="cursor-pointer">Profile</db-link></li>
                        <li>{{editProfile ? "Bestehendes Profil bearbeiten" : "Neues Profil erstellen"}}</li>
                    </db-breadcrumb>

                    <div class="flex flex-col sm:flex-row gap-5 mt-3">

                        <div class="flex flex-col gap-2 sm:w-5/12">
                            <p class="text-sm">Allgemein</p>

                            <db-input label="Name (max. 30 Zeichen)" maxlength="30" variant="outline"
                                v-model="loadedProfile.name" :ariainvalid="errors.invalidName"></db-input>

                            <p class="text-red-500 text-sm" v-if="errors.invalidName">Bitte Namen für Profil festlegen!</p>

                            <div class="h-0.5 w-full bg-gray-300 rounded-md my-3"></div>

                            <p class="text-sm">Lizenzen auswählen</p>

                            <db-input label="Suche" variant="outline" @change="filter = $event.target.value"
                                @keydown="filter = $event.target.value"></db-input>

                            <div class="w-full h-80 p-2 rounded-md flex flex-col gap-1 overflow-y-scroll"
                                style="border: solid 1px rgba(40, 45, 55, 0.25)">
                                <template v-for="i in filteredAndSortedLicenses" :key="i">
                                    <div
                                        class="w-full flex-shrink-0 h-auto rounded-md flex flex-col items-start px-3 gap-2 bg-white shadow-md">
                                        <div class="flex w-full mt-3 items-center">
                                            <db-icon icon="document"></db-icon>
                                            <p class="text-sm mb-0.5 truncate font-medium">{{ i.name }}</p>
                                        </div>
                                        <div class="flex w-full items-center mb-1">
                                            <db-input :value="loadedProfile.selectedItems.find(f => f.skuId == i.skuId)?.min" type="number"
                                                @change="($event) => { loadedProfile.selectedItems.find(f => f.skuId == i.skuId).min = $event.target.value }"
                                                :disabled="!loadedProfile.selectedItems.find(f => f.skuId == i.skuId)"
                                                variant="outline" label="Schwellenwert"
                                                style="transform: scale(0.8); transform-origin: left center;"></db-input>
                                            <db-checkbox class="ml-auto" @click="{
                                                if ($event.target.checked) {
                                                    loadedProfile.selectedItems.push({ ...i, min: 10 });
                                                } else {
                                                    loadedProfile.selectedItems = loadedProfile.selectedItems.filter(f => f.skuId != i.skuId)
                                                }
                                            }"
                                                :checked="loadedProfile.selectedItems.find(f => f.skuId == i.skuId)"></db-checkbox>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="h-0.5 w-full bg-gray-300 rounded-md mt-2 block sm:hidden"></div>

                        <div class="flex flex-col gap-2 sm:w-7/12">
                            <p class="text-sm">Benachrichtigung via Mail</p>

                            <db-input label="Interval (in Tagen)" variant="outline" class="" type="number" value="1"
                                v-model="loadedProfile.mailingIntInDays"></db-input>

                            <div class="w-full h-32 p-2 rounded-md flex flex-col gap-1 overflow-y-scroll"
                                style="border: solid 1px rgba(40, 45, 55, 0.25)">
                                <template v-for="i in loadedProfile.mails" :key="i">
                                    <div class="w-full h-12 rounded-md flex items-center px-3 gap-2 bg-white shadow-md">
                                        <db-icon icon="link"></db-icon>
                                        <p class="text-sm mb-0.5 truncate font-medium">{{ i }}</p>
                                        <db-button icon="delete" icononly class="ml-auto -mr-2"
                                            @click="() => { loadedProfile.mails = loadedProfile.mails.filter(f => f != i); }">Mail
                                            löschen</db-button>
                                    </div>
                                </template>
                            </div>

                            <div class="flex gap-1">
                                <db-input label="Mail" variant="outline" class="flex-auto" ref="newMailInput"
                                    :ariainvalid="errors.invalidMail"></db-input>
                                <db-button variant="secondary-outline" @click="() => {
                                    let input = $refs.newMailInput.$el.children[0].value;
                                    if (input.includes('@')) {
                                        loadedProfile.mails.push(input);
                                        $refs.newMailInput.$el.children[0].value = '';
                                        errors.invalidMail = false;
                                    } else {
                                        errors.invalidMail = true;
                                    }
                                }">Hinzufügen</db-button>
                            </div>

                            <p class="text-red-500 text-sm" v-if="errors.invalidMail">Bitte gültige Mail eintragen!</p>

                            <div class="h-0.5 w-full bg-gray-300 rounded-md my-3"></div>

                            <p class="text-sm">Benachrichtigung via Webhook</p>

                            <db-input label="Interval (in Tagen)" variant="outline" class="" type="number" value="1"
                                v-model="loadedProfile.webhookIntInDays"></db-input>

                            <div class="w-full h-32 p-2 rounded-md flex flex-col gap-1 overflow-y-scroll"
                                style="border: solid 1px rgba(40, 45, 55, 0.25)">
                                <template v-for="i in loadedProfile.webhooks" :key="i">
                                    <div class="w-full h-12 rounded-md flex items-center px-3 gap-2 bg-white shadow-md">
                                        <db-icon icon="link"></db-icon>
                                        <p class="text-sm mb-0.5 truncate font-medium">{{ i }}</p>
                                        <db-button icon="delete" icononly class="ml-auto -mr-2"
                                            @click="() => { loadedProfile.webhooks = loadedProfile.webhooks.filter(f => f != i); }">Webhook
                                            löschen</db-button>
                                    </div>
                                </template>
                            </div>

                            <div class="flex gap-1">
                                <db-input label="Webhook" variant="outline" class="flex-auto" ref="newWebhookInput"
                                    :ariainvalid="errors.invalidWebhook"></db-input>
                                <db-button variant="secondary-outline" @click="() => {
                                    let input = $refs.newWebhookInput.$el.children[0].value;
                                    if (input.includes('https://')) {
                                        loadedProfile.webhooks.push(input);
                                        $refs.newWebhookInput.$el.children[0].value = '';
                                        errors.invalidWebhook = false;
                                    } else {
                                        errors.invalidWebhook = true;
                                    }
                                }">Hinzufügen</db-button>
                            </div>

                            <p class="text-red-500 text-sm" v-if="errors.invalidWebhook">Bitte gültigen Webhook eintragen!
                            </p>
                        </div>

                    </div>
                    <div class="mt-5 ml-auto flex gap-1">
                        <db-button variant="primary" @click="saveNewProfil">Speichern</db-button>
                        <db-button variant="secondary-outline"
                            @click="{ overlayPage = 0; resetLoadedProfile(); }">Abbrechen</db-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */

export default {
    name: "profileOverlay",
    data() {
        return {
            profiles: [],
            overlayActive: false,
            overlayPage: 0,
            loadedProfile: {
                id: window.uuidv4(),
                name: "",
                selectedItems: [],
                mails: [],
                webhooks: [],
                mailingIntInDays: 1,
                webhookIntInDays: 1,
            },
            errors: {
                invalidMail: false,
                invalidWebhook: false,
                invalidName: false
            },
            filter: "",
            editProfile: false,
            deleteProfileById: ""
        }
    },
    watch: {
        profiles() {
            this.$emit("profilesChanged");
        },
        overlayActive() {
            this.getAllProfiles();
        }
    },
    props: {
        allLicenses: Array
    },
    computed: {
        filteredAndSortedLicenses() {
            return this.allLicenses.filter(f => f.name.includes(this.filter) && f.name != "Nicht gefunden").sort((a,b) => !this.loadedProfile.selectedItems.find(f => f.skuId == a.skuId) ? 1 : -1);
        }
    },
    methods: {
        async undoProfileDeletion() {
            //get last deleted profile
            let profile = this.$appStore.deleteProfiles.shift();

            this.$axios.post("/profile/new", { ...profile }, { params: { tenant: await this.$appStore.getSelectedTenant() }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error } = res;

                if (!error) {
                    this.getAllProfiles();
                    this.overlayPage = 0;
                    this.resetLoadedProfile();
                }
            })
        },
        toggleOverlay(bool = undefined) {
            this.overlayActive = bool | !this.overlayActive;
        },
        async getAllProfiles() {
            await this.$axios.get("/profile/all", { params: { tenant: await this.$appStore.getSelectedTenant() }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error, payload } = res.data;

                if (!error) {
                    this.profiles = payload;
                }
            })
        },
        async saveNewProfil() {
            if (this.loadedProfile.name == "") {
                this.errors.invalidName = true;
                return;
            }

            this.errors.invalidName = false;

            this.$axios.post("/profile/new", { ...this.loadedProfile }, { params: { tenant: await this.$appStore.getSelectedTenant() }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error } = res;

                if (!error) {
                    this.getAllProfiles();
                    this.overlayPage = 0;
                    this.resetLoadedProfile();
                }
            })
        },
        async removeProfile(id) {
            this.$appStore.deleteProfiles.unshift(JSON.parse(JSON.stringify(this.profiles.find(f => f.id == id))));
            this.$axios.delete("/profile/del", { params: { tenant: await this.$appStore.getSelectedTenant(), profileId: id }, headers: { 'Authorization': `Bearer ${this.$userStore.access_token}` } }).then(res => {
                let { error, payload } = res.data;

                if (!error) {
                    this.getAllProfiles();
                }
            });
        },
        resetLoadedProfile() {
            this.loadedProfile = {
                id: window.uuidv4(),
                name: "",
                selectedItems: [],
                mails: [],
                webhooks: [],
                mailingIntInDays: 1,
                webhookIntInDays: 1,
            }
            this.filter = "";
            this.editProfile = false;
            this.deleteProfileById = "";
            this.errors = {
                invalidMail: false,
                invalidWebhook: false,
                invalidName: false
            }
        }
    },
}
</script>